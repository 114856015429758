.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 0 7%;

}

.app__profiles {
    display: flex;
    justify-content: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    

    div {
            width: 40%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin: 2rem;

            h3{
              text-align: start;
              @media screen and (max-width: 1200px) {
                text-align: center;
            }
            
          }

          .p-text{
            text-align: start;
            @media screen and (max-width: 1200px) {
              text-align: center;
          }
          
        }
            
            @media screen and (max-width: 1200px) {
                align-items:center;
                justify-content: center;
                width: 100%;
                text-align: center;
            }

           
    }
    .app__resume-suit {
      padding-top: 14px;
      position: relative;
      width: 80%;
      img {
        border-radius: 3%;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;      }
    }
    
    
  
    

    

    
    .p-text {
        font-size: 1.2rem;
        font-weight: 400;
        color:#000;
        padding-bottom: 20px;
        span {
            color: var(--secondary-color);
            font-weight: 600;
            font-size: 1.5rem;
        }
    }

    .head-text {
        font-size: 2rem;
    }

    .app__footer-cards {
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
      
        .app__footer-card {
          min-width: 290px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
      
          margin: 1rem 0;
          padding: 1rem;
          border-radius: 10px;
          cursor: pointer;
          background-color: #90ee9045;
      
          transition: all 0.3s ease-in-out;
      
          img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;
          }
      
          p {
            font-weight: 500;
          }
          a {
            text-decoration: none;
            font-weight: 500;
            padding-bottom: 0;
          }
          &:hover {
            box-shadow: 0 0 25px #90ee9045;
          }
      
          @media screen and (max-width: 450px) {
            width: 100%;
          }
        }
      
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      
      .app__footer-cards .app__footer-card:last-child {
        background-color: #add8e662;
      }
      
      .app__footer-cards .app__footer-card:last-child:hover {
        box-shadow: 0 0 25px #add8e662;
      }
      

      
}

